import * as React from "react"
import { Link, graphql } from "gatsby"

// components
import Layout from '../components/layout'

// icons
import { IconArrowRight } from '../components/icons';
import IsgBannerDesktop from '../images/isg-banner_desktop.png'
import IsgBannerMobile from '../images/isg-banner_mobile.png'

// brands
import LogoAnbima from '../images/brands/anbima.png'
import LogoB3 from '../images/brands/b3.png'
import LogoBen from '../images/brands/ben.png'
import LogoBlk from '../images/brands/blk.png'
import LogoBnp from '../images/brands/bnp.png'
import LogoCemig from '../images/brands/cemig.png'
import LogoCerc from '../images/brands/cerc.png'
import LogoConductor from '../images/brands/conductor.png'
import LogoHabibs from '../images/brands/habibs.png'
import LogoUnidas from '../images/brands/unidas.png'
import LogoHiperstream from '../images/brands/hiperstream.png'
import LogoReedExhibitions from '../images/brands/reed-exhibitions.png'
import LogoSafra from '../images/brands/safra.png'
import LogoViavarejo from '../images/brands/viavarejo.png'
import LogoXpi from '../images/brands/xpi.png'
import LogoOmni from '../images/brands/omni.png'
import LogoBBCE from '../images/brands/bbce.png'
import LogoBradesco from '../images/brands/bradesco-corretora.png'
import LogoAfya from '../images/brands/afya.png'
import LogoModal from '../images/brands/modal.png'
import LogoLetsbank from '../images/brands/letsbank.png'
import LogoSerpro from '../images/brands/serpro.png'
import LogoSantander from '../images/brands/santander.png'
import LogoStoneX from '../images/brands/stonex.png'
import LogoNuclea from '../images/brands/nuclea.png'
import LogoRtm from '../images/brands/rtm.png'
import LogoPierServ from '../images/brands/pier-serv.png'
import LogoIdWall from '../images/brands/idwall.png'

import LogoHarness from '../images/partners/harness.png'
import LogoGrafana from '../images/partners/grafana.png'
import LogoLina from '../images/partners/lina.png'
import LogoAtlassian from '../images/partners/atlassian.png'
import LogoAws from '../images/partners/aws.png'
import LogoGCloud from '../images/partners/google-cloud.png'
import LogoAzure from '../images/partners/azure.png'
import LogoQuanta from '../images/partners/quanta-junior.png'

import HeroBackgroundDevices from '../images/hero-background-devices.png'

// hero brands
// import LogoHeroXpi from '../images/hero-brands/xpi.svg'
// import LogoHeroB3 from '../images/hero-brands/b3.svg'
// import LogoHeroHabibs from '../images/hero-brands/habibs.svg'
// import LogoHeroViavarejo from '../images/hero-brands/viavarejo.svg'
import { Trans } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';

const ClientList = () => {
  return (
    <>
      <div className="clientList">
        <span className="clientList__item"><img className="image image--pb" alt="Serpro" src={LogoSerpro} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Anbima" src={LogoAnbima} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="B3" src={LogoB3} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Cerc" src={LogoCerc} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Blk" src={LogoBlk} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Letsbank" src={LogoLetsbank} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Afya" src={LogoAfya} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Bradesco Corretora" src={LogoBradesco} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Safra" src={LogoSafra} /></span>
      </div>

      <div className="clientList clientList--alternate">
        <span className="clientList__item"><img className="image image--pb" alt="Conductor" src={LogoConductor} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Habibs" src={LogoHabibs} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Cerc" src={LogoUnidas} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Omni" src={LogoOmni} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="BBCE" src={LogoBBCE} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Viavarejo" src={LogoViavarejo} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Xpi" src={LogoXpi} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Hiperstream" src={LogoHiperstream} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Bnp" src={LogoBnp} /></span>
      </div>

      <div className="clientList clientList--bigger">
        <span className="clientList__item"><img className="image image--pb" alt="Ben" src={LogoBen} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Cemig" src={LogoCemig} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Santander" src={LogoSantander} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="StoneX" src={LogoStoneX} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Nuclea" src={LogoNuclea} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Modal" src={LogoModal} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Reed-Exhibitions" src={LogoReedExhibitions} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Rtm" src={LogoRtm} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Pier Serv" src={LogoPierServ} /></span>
        <span className="clientList__item"><img className="image image--pb" alt="Idwall" src={LogoIdWall} /></span>
      </div>
    </>
  )
}

const IndexPage = () => {
  const { t } = useI18next();

  return (
    <>
      <Layout>
        <section className="relative hero">
          <div className="container grid gap-10 lg:grid-cols-2">
            <div className="hero__body">
              <br />
              <br />
              <br />
              <h1 className="title title--display">
                <Trans i18nKey="hero.title" />
              </h1>
              <p className="lead">
                <Trans i18nKey="hero.lead" />
              </p>
              <div className="hero__action">
                <Link className="button button--inverted" to="/contato">
                  <span>
                    <Trans i18nKey={'hero.button.contact'} />
                  </span>
                </Link>

                <Link className="button button--nude" to="/sobre">
                  <span><Trans i18nKey={'hero.button.about'} /></span>
                  <IconArrowRight className="icon" />
                </Link>
              </div>
            </div>

            <img alt="Devices" className="hero__image" src={HeroBackgroundDevices} />
          </div>
        </section>

        <section className="mt-1 border-black pt-1" style={{
          background: 'url(https://isg-stg.sitefinity.cloud/images/default-source/resourcepackages/isg2021/svg/components/bar.svg) repeat-x 0 -13px', backgroundSize: '500px'
        }}>
          <div className='relative'>
            <picture className='bg-red-200 mt-2'>
              <source
                media="(max-width: 640px)"
                srcSet={IsgBannerMobile}
                className='bg-contain bg-center'
              />
              <img src={IsgBannerDesktop} className='w-full mx-auto object-cover object-center sm:h-80' alt="ISG Vericode banner" />
            </picture>
            <div className='container'>
              <div className="absolute top-0 py-5 z-10 flex flex-col space-y-5">
                <div>
                  <h3 className="text-xl text-white lg:max-w-xl">
                    <Trans i18nKey='section.isg.headline' components={{ span: <span className='bg-secondary' /> }} />
                  </h3>
                  <p className="text-sm text-white sm:max-w-xl">
                    <Trans i18nKey="section.isg.sub-headline" />
                  </p>
                </div>
                <div>
                  <a className="button button--inverted button--small" target='_blank' href={t('section.isg.link')} rel="noreferrer">
                    <span>
                      <Trans i18nKey='section.isg.button' />
                    </span>
                    <IconArrowRight className="icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section--light">
          <div className="container">
            <h4 className="title title--display title--section">
              <Trans i18nKey='section.primary.title' />
            </h4>
          </div>

          <div className="overflow-hidden my-lg">
            <ClientList />
          </div>

          <div className="container">
            <article className="article">
              <p className="lead">
                <Trans i18nKey='section.primary.lead.primary' />
              </p>

              <p className="lead">
                <Trans i18nKey='section.primary.lead.secondary' />
              </p>
            </article>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <article className="article">
              <h3><Trans i18nKey='section.secondary.monitoring.title' /></h3>
              <p><Trans i18nKey='section.secondary.monitoring.lead' /></p>
              <hr className="space" />
              <h3><Trans i18nKey='section.secondary.preventing.title' /></h3>
              <p><Trans i18nKey='section.secondary.preventing.lead' /></p>
              <hr className="space" />
              <h3><Trans i18nKey='section.secondary.eficiency.title' /></h3>
              <p><Trans i18nKey='section.secondary.eficiency.lead' /></p>
            </article>
          </div>
        </section>

        <section className="section section--nopadding">
          <div className="container">
            <div className="card card--horizontal card--light card--rounded">
              <img alt="" className="card__image image image--rounded image--shadow image--pb" src="https://images.unsplash.com/photo-1573496130488-f3bd89d03653?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=540&h=600&q=80" />

              <div className="card__body">
                <h2 className="title title--display">
                  <Trans i18nKey='section.tertiary.title' />
                </h2>
                <p>
                  <Trans i18nKey='section.tertiary.lead' />
                </p>
                <Link className="card__action button button--pink button--nude" to="/contato">
                  <span><Trans i18nKey='section.tertiary.button' /></span>
                  <IconArrowRight className="icon" />
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id='partners' className="section section--light section--padded -mb-lg mt-lg">
          <div className="container text-center">
            <h2 className="mx-auto title title--display title--section">
              <Trans i18nKey='section.partner.title' />
            </h2>

            <div className="grid grid-cols-2 lg:grid-cols-4 mx-auto gap-sm lg:gap-10 mt-lg lg:max-w-4xl">
              <a target='_blank' title={`${t('section.partner.tooltip')}`} href='https://insights.vericode.com.br/grafana-vericode' rel="noreferrer">
                <img className="rounded border-2 hover:border-secondary transition-colors delay-100" src={LogoGrafana} alt="Granfana" />
              </a>
              <a target='_blank' title={`${t('section.partner.tooltip')}`} href='https://insights.vericode.com.br/vericode_atlassian' rel="noreferrer">
                <img className="rounded border-2 hover:border-secondary transition-colors delay-100" src={LogoAtlassian} alt="Atlassian" />
              </a>
              <a target='_blank' title={`${t('section.partner.tooltip')}`} href='https://insights.vericode.com.br/vericode_quanta' rel="noreferrer">
                <img className="rounded border-2 hover:border-secondary transition-colors delay-100" src={LogoQuanta} alt="Quanta Junior" />
              </a>
              <img className="rounded" src={LogoHarness} alt="Harness" />
              <img className="rounded" src={LogoLina} alt="Lina" />
              <img className="rounded" src={LogoAzure} alt="Azure" />
              <img className="rounded" src={LogoGCloud} alt="Google Cloud" />
              <img className="rounded" src={LogoAws} alt="Aws" />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;